import logo from './logo.svg';
import './App.css';
import {Wallet} from "./Wallet";
import React from "react";

function App() {
  return (

          <Wallet/>

  );
}

export default App;
